<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Show Driver</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor :initial="dataItem" @data="save"></editor>
        <!-- end -->
      </v-card-text>
      <!-- end -->
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    dataItem: {},
  }),
  watch: {
    "$route.params.id": {
      handler: "getDriver",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const url = `/drivers/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push("/drivers");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDriver(id) {
      const self = this;
      this.$store
        .dispatch("get", `/drivers/${id}`)
        .then((res) => {
          console.log(res);
          self.dataItem = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>